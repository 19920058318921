
function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}



/**
 * Change the current language in animated way
 */
function changeLanguage(useOriginal) {

    //Change texts
    let changeElements = document.getElementsByClassName('changeLanguage');

    for (let changeElement of changeElements) {

        let originalLanguage = changeElement.getAttribute('data-original-lang');
        let changeLanguage = changeElement.getAttribute('data-change-lang');

        changeElement.classList.add('text-fade-out');

        setTimeout(() => {
            if (useOriginal) {
                changeElementShowingText(changeElement, originalLanguage);
            } else {

                changeElementShowingText(changeElement, changeLanguage);
            }

            changeElement.classList.add('text-fade-in');
            changeElement.classList.remove('text-fade-out');

            setTimeout(() => {
                changeElement.classList.remove('text-fade-in');
            }, 290);
        }, 1000)

    }
}
/**
 * 
 * @param {Element} element 
 * @param {string} languageText 
 */
function changeElementShowingText(element, languageText) {

    if (
        (element.tagName.toLowerCase() === 'input' &&
            element.getAttribute('type') === 'text') ||
        element.tagName.toLowerCase() === 'textarea'
    ) {
        element.setAttribute('placeholder', languageText)
    } else {
        element.innerHTML = languageText;
    }



}

let originalLanguage = null;
let activeLanguage = null;

docReady(() => {
    let languageChangersElements = document.getElementsByClassName('languageChanger');

    for (let languageChanger of languageChangersElements) {
        languageChanger.addEventListener('click', (e) => {
            e.preventDefault();


            let targetLanguage = languageChanger.getAttribute('data-target-lang');

            if (originalLanguage == null) {
                originalLanguage = languageChanger.getAttribute('data-original-lang');
            }


            if (activeLanguage != targetLanguage) {
                activeLanguage = targetLanguage;
                try {
                    changeLanguage(originalLanguage == activeLanguage);
                    showHideLangElements(activeLanguage);
                } catch (e) {
                    console.error(e);
                }
            }
            if (activeLanguage == 'en') {
                window.history.pushState({}, "", '/en');
            } else {
                window.history.pushState({}, "", '/');
            }
        });
    }
});

function showHideLangElements(activeLang) {
    //Hide 

    let showHideElements = document.getElementsByClassName('dependsOnLanguage');

    for (let showHideElement of showHideElements) {

        let visibleOn = showHideElement.getAttribute('data-visible-lang');

        if (activeLang == visibleOn) {
            showHideElement.classList.add('hide-by-lang');
        } else {
            showHideElement.classList.remove('hide-by-lang');
        }


    }
}










